import React from 'react';
import { withLayout } from '../../components/Layout';
import {
    Constrain,
    Swell,
    StylelessButton,
    LinkArrow,
    LinkText,
    HeadingLevel3,
    HeadingLevel2
} from '../../components/ui';
import { PageHeader } from '../../components/PageHeader/PageHeader.component';
import Testimonial from '../../components/Testimonial/Testimonial.component';
import illustrationImage from '../../images/illustrations/why-we-make-waves.svg';
import illustrationImageOutline from '../../images/illustrations/why-we-make-waves-outline.svg';
import { FixedMasthead } from '../../components/Masthead/FixedMasthead.component';
import Helmet from 'react-helmet';
import { BannerAdvert } from '../../components/BannerAdvert/BannerAdvert.component';
import CallToContact from '../../components/CallToContact/CallToContact.component';
import styled from '../../styled-components';
import quotemarkSvg from '../../images/quotemark-full.svg';
import ChevronSvg from '../../images/icons/chevron.svg';
import ryanImage from './images/ryan.jpg';
import kirstenImage from './images/kirsten.jpg';
import jamesImage from './images/james.jpg';
import whyUsSocialImage from './images/why-us-page-social-share.jpg';

import { media, pixelToRem } from '../../utilities';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { AfterContentContact } from '../../components/AfterContentContact/AfterContentContact.component';
import { DarkCTA, CTALink } from '../../components/CTAs/DarkCTA';
import earthIllustrationWhite from '../../images/illustration-earth-no-star--white.svg';
import bCorpPending from '../../images/b-corp-pending.png';
import backgroundSmall from '../../images/deep-sea-blue--small.jpg';
import backgroundMedium from '../../images/deep-sea-blue--medium.jpg';
import backgroundLarge from '../../images/deep-sea-blue--large.jpg';

const FoundersQuote = styled.blockquote`
    position: relative;
    padding-left: 5.5rem;
    margin: 0;
    font-weight: 500;
    font-style: italic;

    &:before {
        content: '';
        position: absolute;
        top: ${pixelToRem(7)};
        left: 0;
        width: 4rem;
        height: 4rem;
        background: url(${quotemarkSvg}) no-repeat;
        background-size: 100%;
    }

    cite {
        display: block;
        font-style: normal;
        text-align: right;
        margin-top: 1rem;

        ${media.l`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
            text-align: left;
        `}
    }

    img {
        display: none !important;

        ${media.l`
            display: block !important;
        `};
    }

    ${media.l`
        display: flex !important; /** Override slick */
        justify-content: space-between;
        font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};

        & > * {
            &:first-child {
                width: 65%;
            }

            &:last-child {
                width: 30%;
            }
        }
    `}
`;

const PrevArrow = styled(StylelessButton)`
    padding: 1rem;

    svg {
        transform: rotate(180deg);
    }
`;

const NextArrow = styled(StylelessButton)`
    padding: 1rem;
`;

const Dot = styled.a`
    display: block;
    width: 1rem;
    height: 1rem;
    border: solid 2px ${({ theme }) => theme.colors.deepBlue.color};
    background-color: #fff;
    transition: background-color 200ms;

    .slick-active & {
        background-color: ${({ theme }) => theme.colors.deepBlue.color};
    }
`;

const DotsWrapper = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    li {
        display: inline-block;
        margin: 0 0.5rem;
    }
`;

const FoundersSlider = styled.div`
    position: relative;
    margin-bottom: 3rem;

    ${media.l`
        margin-bottom: 6rem;
    `}

    ${NextArrow},
    ${PrevArrow} {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -3rem;

        ${media.m`
            left: -5rem;
        `}

        ${media.l`
            left: -8rem;
        `}
    }

    ${NextArrow} {
        right: -3rem;
        left: auto;

        ${media.m`
            right: -5rem;
        `}

        ${media.l`
            right: -8rem;
        `}
    }

    .slick-list {
        margin-bottom: 2rem;
    }
`;

const Intro = styled.p`
    margin-bottom: 3rem;

    span {
        font-family: ${({ theme }) => theme.fontNew.secondary.type};
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level5.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level4.lineHeight};
        font-weight: normal;
        display: block;
        margin-top: 2rem;

        ${media.l`
            font-size: ${({ theme }) =>
                theme.fontNew.secondary.size.level4.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level3.lineHeight};
        `}
    }

    ${media.xl`
        margin-bottom: 6rem;
    `}
`;

const ValuesSection = styled.section`
    background-color: ${({ theme }) => theme.colors.deepBlue.color};
    color: ${({ theme }) => theme.colors.deepBlue.contrast};
    padding: 4.5rem 0;
    margin-bottom: 6rem;

    ${media.l`
        margin-bottom: 12rem;
        padding: 9rem 0;
    `}

    ${HeadingLevel2} {
        font-family: ${({ theme }) => theme.fontNew.secondary.type};
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level3.size};
        line-height: ${({ theme }) =>
            theme.fontNew.secondary.size.level3.lineHeight};
        color: ${({ theme }) => theme.colors.green.color};
        font-weight: normal;
        margin-bottom: 0.5em;

        ${media.xl`
            font-size: ${({ theme }) =>
                theme.fontNew.secondary.size.level2.size};
            line-height: ${({ theme }) =>
                theme.fontNew.secondary.size.level2.lineHeight};
        `}
    }
`;

const ValuesList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    ${media.l`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        & > * {
            width: 47.5%;
        }
    `}
`;

const Value = styled.li`
    padding-left: 3rem;
    border-left: solid 2px ${({ theme }) => theme.colors.green.color};
    margin-bottom: 4.5rem;

    p {
        margin: 0;
    }

    h3 {
        margin-bottom: 0.5em;
    }

    ${media.l`
        margin-bottom:  ${({ theme }) => theme.spacing.xxl};
    `}
`;

const MakeWaves = styled.div`
    max-width: 62rem;
    text-align: center;
    margin: 0 auto;

    p {
        font-weight: 500;
        margin: 0;
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level5.size};
    }
`;

const PageWrapper = styled.div`
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 170px;
        background-color: ${({ theme }) => theme.colors.green.color};
        height: 2px;
        left: 50%;
        transform: translateX(-50%);
    }

    a {
        color: ${({ theme }) => theme.colors.deepBlue.color};
        transition: all 100ms;

        &:hover {
            text-decoration-color: ${({ theme }) => theme.colors.green.color};
        }
    }

    ${HeadingLevel3} {
        margin-bottom: 3rem;
    }

    ${Swell} {
        fill: ${({ theme }) => theme.colors.deepBlue.color};
    }
`;

const CTAWrapper = styled.div`
    margin: 3rem auto;

    a {
        color: white;
    }

    ${media.l`
        margin: 6rem auto;
        max-width: 85.6rem;
    `}

    ${media.xl`
        max-width: 105.6rem;
    `}
`;

const Mission = styled.p`
    margin-bottom: ${({ theme }) => theme.spacing.xl};
    font-weight: 500;
`;

export const BCorpCallout = styled.div`
    background-image: url(${backgroundSmall});
    color: white;
    padding: 1.5rem;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 3rem;
    align-items: center;
    text-align: center;
    
    ${media.s`
        flex-direction: row;
        justify-content: space-between;
        text-align: left;

        & > *:last-child {
            margin-left: 1.5rem;
        }
    `}

    ${media.m`
        background-image: url(${backgroundMedium});
    `}

    ${media.l`

        background-image: url(${backgroundLarge});
  
    `}

    ${media.xl`
        padding: 3rem;
        margin-bottom: 6rem;
    `}


    ${HeadingLevel3} {
        margin-bottom: 1.5rem;
    }
    
    a {
        color: white;
    }

    img {
        width: auto;
        margin-bottom: 2rem;
        ${media.s`
            margin-bottom:0
        `}
    }
`;
const WhyUsPage = () => {
    const socialMetaDescription =
        'We’re proposing a new approach to software development, a human-centred approach that puts collaboration, relationships and learning at its heart.';
    const socialMetaTitle = 'Why us?';
    return (
        <>
            <Helmet>
                <title>Why us?</title>
                <meta
                    name="description"
                    content="Learn more about us. We’re proposing a new approach to software development, a human-centred approach that puts collaboration, relationships and learning at its heart."
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@wemakewaves" />
                <meta name="twitter:title" content={socialMetaTitle} />
                <meta
                    name="twitter:description"
                    content={socialMetaDescription}
                />
                <meta
                    name="twitter:image"
                    content={`https://wemakewaves.digital${whyUsSocialImage}`}
                />

                <meta property="og:title" content={socialMetaTitle} />
                <meta
                    property="og:description"
                    content={socialMetaDescription}
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content={`https://wemakewaves.digital/why-us`}
                />
                <meta
                    property="og:image"
                    content={`https://wemakewaves.digital${whyUsSocialImage}`}
                />
            </Helmet>

            <FixedMasthead />

            <BannerAdvert />

            <PageHeader
                highlightColor="green"
                title="A refreshingly different kind of partnership"
                lineTitle="Why us?"
                imageUrl={illustrationImage}
                imageAlt="Illustration two surfers looking out on the ocean ready to surf, symbolising the start of a new business partnership"
                imageOutlineUrl={illustrationImageOutline}
            />

            <PageWrapper>
                <Constrain type="narrow">
                    <Intro>
                        The way most organisations build software is rooted in{' '}
                        <a
                            href="https://corporate-rebels.com/freedom-trust/"
                            rel="nofollow"
                            target="_blank"
                        >
                            Industrial Age Thinking
                        </a>
                        . This way of thinking is outmoded and ineffective for
                        today's collaborative knowledge work. Luckily, change is
                        coming; change initiated by a recognition there are more
                        effective, and joyful ways in which we can work together
                        to deliver better outcomes. We’re proposing a new
                        approach to software development, a human-centred
                        approach that puts collaboration, relationships and
                        learning at its heart.
                        <span>We’re inviting you on the journey.</span>
                    </Intro>

                    <BCorpCallout>
                        <div>
                            <HeadingLevel3>
                                Using our business as a force for good
                            </HeadingLevel3>
                            <p>
                                We believe businesses that balance purpose and
                                profit are essential for a positive and healthy
                                future. This is why we're well on our way to
                                becoming a certified{' '}
                                <a
                                    href="https://bcorporation.net/"
                                    target="_blank"
                                >
                                    B Corp
                                </a>
                                .
                            </p>
                        </div>

                        <img
                            src={bCorpPending}
                            width="100"
                            alt="Certitified B Corporation Pending"
                        />
                    </BCorpCallout>

                    <HeadingLevel3>From the founders</HeadingLevel3>

                    <FoundersSlider>
                        <Slider
                            dots={true}
                            infinite={true}
                            speed={500}
                            slidesToShow={1}
                            slidesToScroll={1}
                            initialSlide={Math.floor(Math.random() * 3)}
                            nextArrow={
                                <NextArrow>
                                    <ChevronSvg />
                                </NextArrow>
                            }
                            prevArrow={
                                <PrevArrow>
                                    <ChevronSvg />
                                </PrevArrow>
                            }
                            appendDots={dots => (
                                <DotsWrapper>{dots}</DotsWrapper>
                            )}
                            customPaging={() => <Dot />}
                        >
                            <FoundersQuote>
                                <section>
                                    I've been involved in software development
                                    for over 20 years but my epiphany came 5
                                    years ago when I realised that the most
                                    effective way to create anything remotely
                                    complicated, requiring multiple skill sets
                                    and diverse knowledge, is to focus on the
                                    relationships between all those involved.
                                    The rest looks after itself.
                                </section>
                                <section>
                                    <img
                                        src={kirstenImage}
                                        alt="Kirsten Minshall, Co-founder of We Make Waves"
                                    />
                                    <cite>Kirsten Minshall</cite>
                                </section>
                            </FoundersQuote>
                            <FoundersQuote>
                                <section>
                                    Like many of our clients I've always had a
                                    desire to forge my own path. Taking that
                                    passion for entrepreneurship and combining
                                    it with years of product development
                                    expertise, I can offer advice, deliver
                                    software and have a positive impact on the
                                    lives of our clients and their users.
                                </section>
                                <section>
                                    <img
                                        src={jamesImage}
                                        alt="James Dowell, Co-founder of We Make Waves"
                                    />
                                    <cite>James Dowell</cite>
                                </section>
                            </FoundersQuote>

                            <FoundersQuote>
                                <section>
                                    My satisfaction comes from seeing the impact
                                    my work has on people's lives. I'm relishing
                                    being able to nurture a company that doubles
                                    down on solving the right problems, support
                                    building viable businesses and ensure ideas
                                    make a difference in the world.
                                </section>
                                <section>
                                    <img
                                        src={ryanImage}
                                        alt="Ryan Hyslop, Co-founder of We Make Waves"
                                    />
                                    <cite>Ryan Hyslop</cite>
                                </section>
                            </FoundersQuote>
                        </Slider>
                    </FoundersSlider>
                </Constrain>

                <Swell />
                <ValuesSection>
                    <Constrain>
                        <HeadingLevel2>Our mission</HeadingLevel2>

                        <Mission>
                            To build software that's the right answer to the
                            right problem, using our business to inspire and
                            implement solutions that have a lasting positive
                            impact on people and planet.
                        </Mission>

                        <HeadingLevel2>What rocks our boat</HeadingLevel2>

                        <ValuesList>
                            <Value>
                                <h3>
                                    Relationships, relationships, relationships
                                </h3>
                                <p>
                                    The cornerstone of all success, all
                                    advancement and innovation is founded on
                                    personal relationships. Spend some time each
                                    day learning about someone else: a customer,
                                    a user, or a colleague. Nurture the
                                    relationships and the rest will look after
                                    itself.
                                </p>
                            </Value>
                            <Value>
                                <h3>Tough problems, solved together</h3>
                                <p>
                                    Join forces to help each other solve
                                    problems and achieve far more than we could
                                    ever dream of doing alone. Help others to
                                    reach their full potential, celebrate
                                    together and support each other during
                                    difficult times.
                                </p>
                            </Value>
                            <Value>
                                <h3>Honesty. Always</h3>
                                <p>
                                    Actively seek and give feedback, embracing
                                    it as a tool for continuous learning. Ask
                                    for the things you need and listen to
                                    others. Always empathise, never judge. And
                                    remember, honesty is always the best policy.
                                </p>
                            </Value>
                            <Value>
                                <h3>Optimise for learning</h3>
                                <p>
                                    To reach our highest potential explore your
                                    curiosity each and every day. Accept
                                    mistakes as the fuel for learning and share
                                    each new discovery for the benefit of
                                    everyone.
                                </p>
                            </Value>
                            <Value>
                                <h3>Embrace the challenge</h3>
                                <p>
                                    Accept that solutions are emergent not
                                    fixed. Embrace the unknown, meeting each new
                                    challenge head on with an open mind and
                                    nimble approach, with the confidence this
                                    attitude will enable you to find a way.
                                </p>
                            </Value>
                            <Value>
                                <h3>Radical trust</h3>
                                <p>
                                    Allow others to be free to make their own
                                    decisions and never try to control someone
                                    else. In the end, use your freedom
                                    responsibly by doing what feels right rather
                                    than following the rules.
                                </p>
                            </Value>

                            <Value>
                                <h3>Socially responsible</h3>
                                <p>
                                    Use our expertise to tackle social and
                                    environmental challenges. Choose to fight
                                    for people and planet, speak up and keep our
                                    ship on this course.
                                </p>
                            </Value>

                            <Value>
                                <h3>Make Waves</h3>
                                <p>
                                    There's no defined path to excellence; we'll
                                    need to plot our own course. Challenge the
                                    status quo, always ask why and embrace those
                                    that challenge us.
                                </p>
                            </Value>
                        </ValuesList>

                        <MakeWaves>
                            <p>
                                Never settle: treading water is boring, let's
                                make waves!
                            </p>
                        </MakeWaves>
                    </Constrain>
                </ValuesSection>

                <Constrain type="narrow">
                    <HeadingLevel2>
                        Don't just take our word for it.
                    </HeadingLevel2>
                </Constrain>

                <Testimonial
                    quote="Working with We Make Waves delivered more than just the tangible outputs we originally anticipated. The team challenged us to think about our approach to problem solving and product development in a way that we could apply outside of the specific project we were working on."
                    by="Rich Stock"
                    title="Head of Commercial, British Rowing"
                    color="green"
                />

                <CTAWrapper>
                    <DarkCTA>
                        <div>
                            <h4>Making an ethical impact</h4>
                            <p>
                                Do you have an idea for a digital product in the
                                ethical space? We're keen to use our expertise
                                to tackle social and environmental challenges.
                                <CTALink>
                                    <LinkArrow
                                        to="/making-an-ethical-impact"
                                        arrowColor="green"
                                    >
                                        <LinkText underlineColor="green">
                                            Find out how we're making an ethical
                                            impact.
                                        </LinkText>
                                    </LinkArrow>
                                </CTALink>
                            </p>
                        </div>

                        <img
                            src={earthIllustrationWhite}
                            alt="Making an ethical impact"
                        />
                    </DarkCTA>
                </CTAWrapper>
            </PageWrapper>

            <Constrain>
                <AfterContentContact />
            </Constrain>

            <CallToContact />
        </>
    );
};

export default withLayout(WhyUsPage);
