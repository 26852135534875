import { media } from '../../utilities';
import backgroundSmall from '../../images/deep-sea-blue--small.jpg';
import backgroundMedium from '../../images/deep-sea-blue--medium.jpg';
import backgroundLarge from '../../images/deep-sea-blue--large.jpg';
import styled from '../../styled-components';

export const DarkCTA = styled.div`
    background-image: url(${backgroundSmall});
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    font-weight: 500;
    padding: 3rem;

    h4 {
        font-family: ${({ theme }) => theme.fontNew.secondary.type};
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level5.size};
        line-height: ${({ theme }) =>
            theme.fontNew.secondary.size.level5.lineHeight};
        color: ${({ theme }) => theme.colors.green.color};
        font-weight: normal;
        margin-bottom: 1rem;

        ${media.xl`
            font-size: ${({ theme }) =>
                theme.fontNew.secondary.size.level4.size};
            line-height: ${({ theme }) =>
                theme.fontNew.secondary.size.level4.lineHeight};
        `}
    }

    p {
        margin: 0;

        ${media.m`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level5.lineHeight};
        `}

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level4.lineHeight};
        `}
    }

    a {
        color: white;
    }

    img {
        display: none;

        ${media.m`
            display: block;
            width: 22%;
        `}
    }

    ${media.m`
        background-image: url(${backgroundMedium});
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    `}

    ${media.l`
        background-image: url(${backgroundLarge});
    `}
`;

export const CTALink = styled.span`
    display: block;
    margin-top: 1rem;
`;
