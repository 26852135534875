import React from 'react';
import styled from '../../styled-components';
import { LinkArrow, Constrain, LinkText } from '../ui';
import { ShownOnDesktop } from '../ui/ShownOnDesktop';
import { InlineP } from '../ui/InlineP';

const UnstyledBannerAdvert: React.FC<wmw.IBaseStyledComponentProps> = ({
    className
}) => (
    <div className={className}>
        <Constrain>
            <ShownOnDesktop>
                Want to make a success of your digital product?
            </ShownOnDesktop>{' '}
            <InlineP>
                Learn about our{' '}
                <LinkArrow to="/the-wave-machine-digital-product-clinic">
                    <LinkText underlineColor="pink">
                        free product clinic.
                    </LinkText>
                </LinkArrow>
            </InlineP>
        </Constrain>
    </div>
);

export const BannerAdvert = styled(UnstyledBannerAdvert)`
    padding: 1.5rem 0;
    background-color: ${({ theme }) => theme.colors.deepBlue.color};
    color: #fff;
    text-align: center;

    a {
        display: inline-flex;
        color: #fff;
        font-weight: normal;

        svg {
            fill: ${({ theme }) => theme.colors.pink.color};
        }
    }
`;
