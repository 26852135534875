import * as React from 'react';
import styled, { css } from '../../styled-components';
import { media } from '../../utilities';
import { Link, StylelessLink } from '../ui';
import backgroundMedium from '../../images/deep-sea-blue--medium.jpg';
import twitterIcon from '../../images/social/twitter.svg';
import linkedinIcon from '../../images/social/linkedin.svg';
import instagramIcon from '../../images/social/instagram.svg';
import facebookIcon from '../../images/social/facebook.svg';

const ApostropheWorkaround = styled.span`
    position: absolute;
    margin-top: -6px;
    margin-left: 10px;
`;

const Heading = styled.h4`
    font-family: ${({ theme }) => theme.fontNew.secondary.type};
    font-size: ${({ theme }) => theme.fontNew.secondary.size.level4.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level3.lineHeight};
    font-weight: normal;

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level3.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level2.lineHeight};
    `}
`;

const SocialIcons = styled.div`
    display: inline-flex;
    align-items: center;
    list-style: none;
    margin: -1rem;

    a {
        padding: 1rem;
        margin: 0 0.5rem;
    }
`;

interface ICallToContactProps extends wmw.IBaseStyledComponentProps {}

export class UnstyledCallToContact extends React.Component<
    ICallToContactProps
> {
    public render() {
        return (
            <div className={this.props.className} id="get-in-touch">
                <Heading>
                    Let
                    <ApostropheWorkaround>'</ApostropheWorkaround> s make waves
                </Heading>

                <div className="contact-details">
                    <p className="contact-info">
                        <Link to="mailto:together@wemakewaves.digital">
                            together@wemakewaves.digital
                        </Link>
                    </p>

                    <p className="contact-info">
                        <Link to="tel:02037443554">020 3744 3554</Link>
                    </p>
                </div>

                <SocialIcons>
                    <StylelessLink
                        to="https://twitter.com/wemakewaves"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={twitterIcon} alt="Twitter logo" />
                    </StylelessLink>
                    <StylelessLink
                        to="https://www.linkedin.com/company/wemakewaves"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={linkedinIcon} alt="LinkedIn logo" />
                    </StylelessLink>
                    <StylelessLink
                        to="https://www.instagram.com/wemakewaves.digital/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={instagramIcon} alt="Instagram logo" />
                    </StylelessLink>
                    <StylelessLink
                        to="https://www.facebook.com/wemakewaves/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={facebookIcon} alt="Facebook logo" />
                    </StylelessLink>
                </SocialIcons>
            </div>
        );
    }
}

export default styled(UnstyledCallToContact)`
    ${({ theme }) => css`
        text-align: center;
        background: ${theme.colors.deepBlue.color} url(${backgroundMedium})
            center center;
        background-size: cover;
        color: ${theme.colors.deepBlue.contrast};
        padding: 3rem;

        .contact-details {
            font-size: ${theme.fontNew.primary.size.level5.size};
            margin-bottom: 3rem;

            ${media.xl`
                font-size: ${theme.fontNew.primary.size.level4.size};
            `}
        }

        .contact-info {
            margin-bottom: 1.6rem;
        }

        ${Heading} {
            margin-bottom: 1rem;
        }

        ${Link} {
            color: ${theme.colors.deepBlue.contrast};

            &:before {
                background-color: ${theme.colors.deepBlue.contrast};
            }
        }
    `}
`;
