import styled from '../../styled-components';
import { media } from '../../utilities';

export const ShownOnDesktop = styled.p`
    display: none;
    margin: 0;

    ${media.xl`
        display: inline-block;
    `}
`;
